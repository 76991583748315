import React from "react";
import {
    mapping,
    IconSVG,
    RiskMatrix,
    BarChart,
    Pill,
} from "@aim-mf/styleguide";
import { navigateToUrl } from "single-spa";

class MatrixExplanation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div style={WrapperStyle}>
                <div style={{ margin: "70px 0px 0 292px" }}>
                    <div>
                        <div
                            style={{
                                ...mapping["display/d4/lightleft"],
                                display: "inline-flex",
                            }}
                        >
                            Risk Matrix Help
                        </div>
                        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                        <div
                            className={"div_hover"}
                            style={{
                                display: "inline-flex",
                                marginLeft: "1000px",
                            }}
                            onClick={this.props.handleInformationClose}
                        >
                            <IconSVG
                                name={"cancel"}
                                color={mapping["Color_Basic_Light"]}
                                size={"2.0"}
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            display: "inline-flex",
                            alignItems: "top",
                            marginTop: "66px",
                        }}
                    >
                        <VerticalAxisExplanation />
                        <DemoRiskMatrix
                            matrixSetting={this.props.riskMatrixSetting}
                            categoryList={this.props.categoryList}
                        />
                        <RatingExplanationSection />
                    </div>
                    <HorizontalAxisExplanationSection />
                </div>
            </div>
        );
    }
}

const RatingExplanationSection = (props) => {
    return (
        <div>
            <div style={RiskRatingExplanationStyle}>
                <div style={ExplanationTitleFont}>Magnitude of Impact</div>
                <div style={{ display: "inline-flex" }}>
                    <div>
                        <RatingExplanation
                            title={"Gross"}
                            content={
                                "The gross risk is the amount of damage caused by a risk when all preventive measures fail."
                            }
                        />
                        <RatingExplanation
                            title={"Residual"}
                            content={
                                "Residual risk is the threat that remains after all efforts to identify and eliminate risk have been made. "
                            }
                        />
                        <RatingExplanation
                            title={"Target"}
                            content={
                                " The amount of risk an investor is willing to take. Target risk varies according to the investor's risk tolerance."
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const HorizontalAxisExplanationSection = (props) => {
    return (
        <div>
            <div style={HorizontalAxisExplanationStyle}>
                <div style={ExplanationTitleFont}>Magnitude of Impact</div>
                <div style={{ display: "inline-flex" }}>
                    <div>
                        <HorizontalExplanation
                            number={1}
                            title={"Very Low"}
                            content={
                                "No impact to full mission success criteria."
                            }
                        />
                        <HorizontalExplanation
                            number={2}
                            title={"Low"}
                            content={
                                "Minor impact to full mission success criteria."
                            }
                        />
                        <HorizontalExplanation
                            number={3}
                            title={"Moderate"}
                            content={
                                "Moderate impact to full mission success criteria. Minimum mission success criteria is achievable with margin."
                            }
                        />
                        <HorizontalExplanation
                            number={4}
                            title={"High"}
                            content={
                                "Major impact to full mission success criteria. Minimum mission success criteria is achievable. "
                            }
                        />
                        <HorizontalExplanation
                            number={5}
                            title={"Very High"}
                            content={
                                "Minimum mission success criteria is not achievable. "
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
const DemoRiskMatrix = (props) => {
    return (
        <div
            style={{
                transformOrigin: "center top",
                transform: "scale(0.8)",
                marginBottom: "-50px",
            }}
        >
            <RiskMatrix
                matrixSetting={props.riskMatrixSetting}
                riskRating={[
                    {
                        name: "Selected Risk Name",
                        gross: { likelihood: 2, impact: 2 },
                        residual: { likelihood: 3, impact: 3 },
                        target: { likelihood: 4, impact: 4 },
                    },
                ]}
                singleRisk={true}
                categoryList={props.categoryList}
            />
        </div>
    );
};

const VerticalAxisExplanation = (props) => {
    return (
        <div>
            <div style={VerticalAxisExplanationStyle}>
                <div style={ExplanationTitleFont}>Likelihood of Occurrence</div>
                <AxisExplanation
                    number={5}
                    title={"Very Likely"}
                    content={
                        "May cause death or permanently disabling injury or destruction of property."
                    }
                />
                <AxisExplanation
                    number={4}
                    title={"Likely"}
                    content={
                        "May cause severe injury or occupational illness or major property damage."
                    }
                />
                <AxisExplanation
                    number={3}
                    title={"Moderate"}
                    content={
                        "May cause minor injury or occupational illness or minor property damage."
                    }
                />
                <AxisExplanation
                    number={2}
                    title={"Unlikely"}
                    content={
                        "Could cause the need for only minor first aid treatment."
                    }
                />
                <AxisExplanation
                    number={1}
                    title={"Very Unlikely"}
                    content={"Negligible or No impact."}
                />
            </div>
        </div>
    );
};
const AxisExplanation = (props) => {
    return (
        <div style={{ display: "inline-flex", marginBottom: "26px" }}>
            <div style={ExplanationContentNumberWrapperStyle}>
                <div style={ExplanationContentNumberFont}>{props.number}</div>
            </div>
            <div style={{ marginLeft: "11px" }}>
                <div style={AxisExplanationContentSubtitleFont}>
                    {props.title}
                </div>
                <div style={AxisExplanationContentDescriptionFont}>
                    {props.content}
                </div>
            </div>
        </div>
    );
};
const RatingExplanation = (props) => {
    return (
        <div style={{ display: "inline-flex", marginBottom: "26px" }}>
            <div style={{ marginRight: "11px" }}>
                <div style={RatingExplanationContentSubtitleFont}>
                    {props.title}
                </div>
                <div style={RatingExplanationContentDescriptionFont}>
                    {props.content}
                </div>
            </div>
        </div>
    );
};
const HorizontalExplanation = (props) => {
    return (
        <div style={{ display: "inline-flex", marginBottom: "26px" }}>
            <div style={{ marginRight: "30px" }}>
                <div style={ExplanationContentNumberWrapperStyle}>
                    <div style={ExplanationContentNumberFont}>
                        {props.number}
                    </div>
                </div>
                <div style={{ height: "12px" }} />
                <div style={RatingExplanationContentSubtitleFont}>
                    {props.title}
                </div>
                <div style={RatingExplanationContentDescriptionFont}>
                    {props.content}
                </div>
            </div>
        </div>
    );
};
const WrapperStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "1920px",
    height: "1080px",
};

const VerticalAxisExplanationStyle = {
    paddingLeft: "39px",
    height: "541px",
    width: "336px",
    borderRadius: "4px",
    backgroundColor: "#505D75",
};
const RiskRatingExplanationStyle = {
    paddingLeft: "39px",
    height: "213px",
    width: "600px",
    borderRadius: "4px",
    backgroundColor: "#505D75",
};
const HorizontalAxisExplanationStyle = {
    paddingLeft: "39px",
    height: "271px",
    width: "1028px",
    borderRadius: "4px",
    backgroundColor: "#505D75",
};
const ExplanationTitleFont = {
    paddingTop: "21px",
    marginBottom: "26px",
    width: "218px",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "26px",
};
const ExplanationContentNumberWrapperStyle = {
    height: "40px",
    width: "40px",
    backgroundColor: "#D8D8D8",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};
const ExplanationContentNumberFont = {
    height: "25px",
    width: "13px",
    color: "#232C3D",
    fontFamily: "Roboto",
    fontSize: "22px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "25px",
};
const AxisExplanationContentSubtitleFont = {
    height: "20px",
    width: "197px",
    color: "#A9D1FF",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "20px",
};
const AxisExplanationContentDescriptionFont = {
    width: "197px",
    color: "#EAF0F7",
    fontFamily: "Roboto",
    fontSize: "14px",
    letterSpacing: "0",
    lineHeight: "20px",
};
const RatingExplanationContentSubtitleFont = {
    height: "20px",
    width: "154px",
    color: "#A9D1FF",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "20px",
};
const RatingExplanationContentDescriptionFont = {
    width: "154px",
    color: "#EAF0F7",
    fontFamily: "Roboto",
    fontSize: "14px",
    letterSpacing: "0",
    lineHeight: "20px",
};
export { MatrixExplanation };
