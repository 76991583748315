import React, { useCallback } from "react";
import { Dashboard } from "./page/dashboard";
import {
    fetchAPIWithPermission,
    fetchUserAPIWithPermission,
    fetchAdminAPIWithPermission,
} from "@aim-mf/api";
import { AuthCheckAndAutofix } from "@aim-mf/styleguide";
const companyName = "Fortune Happy Pte. Ltd.";

const riskMatrixSetting = [
    ["2", "3", "3", "3", "3"],
    ["1", "2", "2", "3", "3"],
    ["1", "1", "2", "3", "3"],
    ["1", "1", "2", "2", "3"],
    ["1", "1", "2", "2", "2"],
];

const riskNumberPerRow = 2;

const categoryList = ["gross", "residual", "target"];

const initialState = {
    loadingRisk: true,
    loadingUser: true,
    riskList: [],
    riskDetail: {},
    userList: {},
};

function App(props) {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const { loadingRisk, loadingUser, riskList, userList } = state;

    React.useEffect(() => {
        if (loadingUser) {
            dispatch({ type: "loadingUser" });
            const userSubscription = fetchAdminAPIWithPermission(
                "auth/profile/list"
            ).subscribe(
                (results) => {
                    console.log("useEffect loadingUser finish");
                    dispatch({ type: "loadUser", results });
                },
                (err) => {
                    AuthCheckAndAutofix(err, () => {
                        loadUserList();
                    });
                }
            );
            return () => userSubscription.unsubscribe();
        }

        if (loadingRisk) {
            let param = { data: { isArchived: "NON-ARCHIVED" } };
            dispatch({ type: "loadingRisk" });
            const subscription = fetchAPIWithPermission(
                "getrisks",
                param
            ).subscribe(
                (results) => {
                    let newResults = { results: results, userList: userList };
                    dispatch({ type: "newRisk", newResults });
                },
                (err) => {
                    console.log("err", err); // eslint-disable-line
                    AuthCheckAndAutofix(err, () => {
                        loadRisk();
                    });
                }
            );

            return () => subscription.unsubscribe();
        }
    }, [loadingRisk, loadingUser, userList]);

    const loadUserList = useCallback(() => {
        console.log("call back to load user list");
        dispatch({ type: "loadingUser" });
        const userSubscription = fetchAdminAPIWithPermission(
            "auth/profile/list"
        ).subscribe(
            (results) => {
                dispatch({ type: "loadUser", results });
            },
            (err) => {
                console.log("err", err); // eslint-disable-line
                AuthCheckAndAutofix(err, () => {
                    loadUserList();
                });
            }
        );

        return () => userSubscription.unsubscribe();
    });

    const loadRisk = useCallback(() => {
        if (loadingRisk) {
            let param = { data: { isArchived: "NON-ARCHIVED" } };
            dispatch({ type: "loadingRisk" });
            const subscription = fetchAPIWithPermission(
                "getrisks",
                param
            ).subscribe(
                (results) => {
                    let newResults = { results: results, userList: userList };
                    dispatch({ type: "newRisk", newResults });
                },
                (err) => {
                    console.log("err", err); // eslint-disable-line
                    AuthCheckAndAutofix(err, () => {
                        loadRisk();
                    });
                }
            );

            return () => subscription.unsubscribe();
        }
    });

    const riskRating = () => {
        let parentRisk = riskList.filter((item) => {
            return item.isParentRisk;
        });
        return parentRisk;
    };
    return (
        <div>
            <Dashboard
                loading={loadingRisk || loadingUser}
                userList={userList}
                companyName={companyName}
                riskList={riskList}
                riskMatrixSetting={riskMatrixSetting}
                categoryList={categoryList}
                riskNumberPerRow={riskNumberPerRow}
                riskRating={riskRating()}
            />
        </div>
    );
}
function reducer(state = initialState, action) {
    switch (action.type) {
        case "loadingRisk":
            return { ...state, loadingRisk: true };
        case "loadingUser":
            return { ...state, loadingUser: true };
        case "newRisk":
            //console.log(action.newResults.userList);
            let riskList = action.newResults.results.data;
            riskList.forEach((risk, riskIndex) => {
                risk.owner.forEach((username, ownerIndex) => {
                    let display_name = action.newResults.userList.filter(
                        (user) => {
                            return user.username === username;
                        }
                    )[0].display_name;
                    riskList[riskIndex]["owner"][ownerIndex] = display_name;
                });
            });
            return {
                ...state,
                riskList: riskList,
                loadingRisk: false,
            };
        case "loadUser":
            return {
                ...state,
                userList: action.results.data,
                loadingUser: false,
            };
        default:
            throw Error(`Unknown action type '${action.type}'`);
    }
}
export default App;
