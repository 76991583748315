import React from "react";
import {
    mapping,
    RiskTower,
    RiskMatrix,
    DropDownListButton,
    Pill,
    SearchBar,
    Loading,
} from "@aim-mf/styleguide";
import { Charts } from "./component/Charts";
import { MatrixExplanation } from "./component/MatrixExplanation";
import { navigateToUrl } from "single-spa";

const RiskTowerDivStyle = {
    display: "inline-flex",
    paddingRight: "20px",
};
const RiskTowerStyle = {
    paddingLeft: "170px",
    display: "inline-flex",
};
const TitleFontStyle = {
    paddingLeft: "170px",
    paddingTop: "40px",
    paddingBottom: "20px",
    ...mapping["heading/h3/lightleft"],
};

const RiskCountFontStyle = {
    paddingLeft: "170px",
    paddingBottom: "15px",
    ...mapping["heading/h5/lightleft"],
};

const RiskCountAlignStyle = {
    paddingRight: "27px",
    display: "inline-flex",
};

const parentRiskCountBackgroundColor =
    mapping["Color_Parent_Risk_Count_Background"];
const subRiskCountBackgroundColor = mapping["Color_Basic_Primary"] + "33";

const GroupRiskByCategory = (category, riskList) => {
    var resultList = riskList.filter((item) => {
        return item.isParentRisk && item.category === category;
    });
    resultList.map((riskitem) => {
        riskitem.subRisk = riskList.filter((risk) => {
            return risk.parentRisk_id === parseInt(riskitem.sourceRiskID);
        });
    });
    let soloSubRisk = riskList.filter((risk) => {
        let parentIDList = resultList.map((risk) => {
            return parseInt(risk.sourceRiskID);
        });
        return (
            risk.category === category &&
            !risk.isParentRisk &&
            !parentIDList.includes(risk.parentRisk_id)
        );
    });
    soloSubRisk.map((riskitem) => {
        riskitem.subRisk = [];
    });
    resultList.push(...soloSubRisk);
    return resultList;
};

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            riskList: this.props.riskList,
            riskMatrixSetting: this.props.riskMatrixSetting,
            categoryList: this.props.categoryList,
            riskNumberPerRow: this.props.riskNumberPerRow,
            riskRating: this.props.riskRating,
            //filter list [tier filter, rating filter, and search filter]
            filterList: ["", "", ""],
            tierFilterList: ["All Tier", "Tier 1", "Tier 2", "Tier 3"],
            currentTier: 0,
            ratingFilterList: ["All", "High", "Medium", "Low"],
            currentRating: 0,
            MatrixExplanation: false,
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (
            JSON.stringify(nextProps.riskList) !==
            JSON.stringify(this.props.riskList)
        ) {
            this.setState({ riskList: nextProps.riskList });
        }
        if (
            JSON.stringify(nextProps.riskRating) !==
            JSON.stringify(this.props.riskRating)
        ) {
            this.setState({ riskRating: nextProps.riskRating });
        }
    }

    HandleRiskTileClick = (ID) => {
        navigateToUrl("/risk-edit/" + ID + "/");
    };

    render() {
        // console.log(this.state.riskList)
        const riskTileHoverCallback = (risk) => {
            this.setState({ riskRating: risk });
        };

        const riskTileUnhoverCallback = () => {
            this.setState({ riskRating: this.props.riskRating });
        };

        var parentRiskCount = this.state.riskList.filter((item) => {
            return item.isParentRisk;
        }).length;
        var subRiskCount = this.state.riskList.length - parentRiskCount;

        let displayData = this.filterData(this.props.riskList);

        return (
            <div>
                <div
                    style={
                        this.state.MatrixExplanation || this.props.loading
                            ? BlurEffectStyle
                            : { dummy: "dummy" }
                    }
                >
                    <div style={TitleFontStyle}> {this.props.companyName} </div>
                    <div
                        style={Object.assign(
                            {},
                            RiskCountFontStyle,
                            RiskCountAlignStyle
                        )}
                    >
                        Total Risks: {this.state.riskList.length}
                    </div>
                    <div style={RiskCountAlignStyle}>
                        <Pill
                            color={parentRiskCountBackgroundColor}
                            content={"Parent Risk: " + parentRiskCount}
                        />
                    </div>
                    <div style={RiskCountAlignStyle}>
                        <Pill
                            color={subRiskCountBackgroundColor}
                            content={"Sub Risk: " + subRiskCount}
                        />
                    </div>
                    <div style={RiskCountAlignStyle}>
                        <SearchBar searchCallBack={this.onSearchCallBack} />
                    </div>
                    <div style={RiskCountAlignStyle}>
                        <DropDownListButton
                            data={this.state.tierFilterList}
                            value={this.state.tierFilterList[0]}
                            onChange={this.onTierChange}
                        />
                    </div>
                    <div style={RiskCountAlignStyle}>
                        <DropDownListButton
                            data={this.state.ratingFilterList}
                            value={this.state.ratingFilterList[0]}
                            onChange={this.onRatingChange}
                        />
                    </div>

                    <div style={RiskTowerStyle}>
                        <div style={RiskTowerDivStyle}>
                            <RiskTower
                                style={{ display: "inline-flex" }}
                                category={"Strategic"}
                                onHoverCallback={riskTileHoverCallback}
                                onUnhoverCallback={riskTileUnhoverCallback}
                                riskList={GroupRiskByCategory(
                                    "Strategic",
                                    displayData
                                )}
                                RiskTileClick={this.HandleRiskTileClick}
                            />
                        </div>
                        <div style={RiskTowerDivStyle}>
                            <RiskTower
                                style={{ display: "inline-flex" }}
                                category={"Operational"}
                                onHoverCallback={riskTileHoverCallback}
                                onUnhoverCallback={riskTileUnhoverCallback}
                                riskList={GroupRiskByCategory(
                                    "Operational",
                                    displayData
                                )}
                                RiskTileClick={this.HandleRiskTileClick}
                            />
                        </div>
                        <div style={RiskTowerDivStyle}>
                            <RiskTower
                                style={{ display: "inline-flex" }}
                                category={"Financial"}
                                onHoverCallback={riskTileHoverCallback}
                                onUnhoverCallback={riskTileUnhoverCallback}
                                riskList={GroupRiskByCategory(
                                    "Financial",
                                    displayData
                                )}
                                RiskTileClick={this.HandleRiskTileClick}
                            />
                        </div>
                        <div style={RiskTowerDivStyle}>
                            <RiskTower
                                style={{ display: "inline-flex" }}
                                category={"Technology"}
                                onHoverCallback={riskTileHoverCallback}
                                onUnhoverCallback={riskTileUnhoverCallback}
                                riskList={GroupRiskByCategory(
                                    "Technology",
                                    displayData
                                )}
                                RiskTileClick={this.HandleRiskTileClick}
                            />
                        </div>
                        <div style={RiskTowerDivStyle}>
                            <RiskTower
                                style={{ display: "inline-flex" }}
                                category={"Compliance"}
                                onHoverCallback={riskTileHoverCallback}
                                onUnhoverCallback={riskTileUnhoverCallback}
                                riskList={GroupRiskByCategory(
                                    "Compliance",
                                    displayData
                                )}
                                RiskTileClick={this.HandleRiskTileClick}
                            />
                        </div>
                    </div>
                    <div style={{ display: "inline-flex" }}>
                        <RiskMatrix
                            matrixSetting={this.state.riskMatrixSetting}
                            riskRating={this.state.riskRating}
                            singleRisk={this.state.riskRating.length === 1}
                            categoryList={this.state.categoryList}
                            handleInformationClick={() => {
                                this.setState({ MatrixExplanation: true });
                            }}
                        />
                    </div>
                    <Charts riskList={this.state.riskList} />
                    <div style={{ height: "50px" }} />
                </div>
                {this.state.MatrixExplanation && (
                    <MatrixExplanation
                        handleInformationClose={() => {
                            this.setState({ MatrixExplanation: false });
                        }}
                        matrixSetting={this.state.riskMatrixSetting}
                        categoryList={this.state.categoryList}
                    />
                )}
                {this.props.loading && (
                    <Loading
                        type={"bars"}
                        color={mapping["Color_Basic_Primary"]}
                    />
                )}
            </div>
        );
    }
    onSearchCallBack = (e) => {
        let filterList = this.state.filterList;
        filterList[2] = e.target.value;
        this.setState({ filterList: filterList });
    };

    onTierChange = (event) => {
        let tier = event.value;
        let tierIndex = this.state.tierFilterList.findIndex((el) => {
            return el === tier;
        });
        let filterList = this.state.filterList;
        filterList[0] = tierIndex;
        this.setState({ filterList: filterList });
    };
    onRatingChange = (event) => {
        let rating = event.value;
        let ratingIndex = this.state.ratingFilterList.findIndex((el) => {
            return el === rating;
        });
        let filterList = this.state.filterList;
        filterList[1] = 4 - ratingIndex;
        this.setState({ filterList: filterList });
    };

    filterData = (riskList) => {
        let displayData = this.state.riskList
            .filter((risk) => {
                return (
                    this.state.filterList[0] === 0 ||
                    risk.tier === this.state.filterList[0]
                );
            })
            .filter((risk) => {
                return (
                    this.state.filterList[1] === 4 ||
                    risk.residual.rating === this.state.filterList[1]
                );
            });

        let searchContent = this.state.filterList[2].toLowerCase();
        if (searchContent !== "") {
            // find the risks that suits the search keywords (risk name and risk owner)
            let riskOnSearch = displayData.filter((riskItem) => {
                let matchOwner = false;
                riskItem.owner.forEach((owner) => {
                    matchOwner =
                        matchOwner ||
                        owner.toLowerCase().includes(searchContent);
                });
                let matchRiskName = riskItem.name
                    .toLowerCase()
                    .includes(searchContent);
                return matchRiskName || matchOwner;
            });

            // if match subrisk, can find its parentrisk information
            let parentRiskList = riskOnSearch
                .map((a) => a.parentRiskId)
                .filter((item) => {
                    return item != "";
                });
            let riskIDList = riskOnSearch.map((a) => a.id);

            parentRiskList.forEach((ID) => {
                // if (!riskIDList.includes(ID)) ;
                let newParentRisk = riskList.filter((propsRiskItem) => {
                    return propsRiskItem.id === ID;
                });
                if (newParentRisk.length > 0) {
                    riskOnSearch.push(newParentRisk[0]);
                }
            });
            displayData = riskOnSearch;
        }
        //console.log(displayData);
        return displayData;
    };
}

const BlurEffectStyle = {
    filter: "blur(10px)",
};

export { Dashboard };
